import { Link } from 'react-router-dom'; // or 'next/link' if using Next.js

const ProfileFooter = () => (
    <footer className="w-full py-6 mt-auto">
        <div className="text-center text-sm text-gray-500">
            powered by{' '}
            <Link
                to="/"
                className="inline-flex items-center font-medium bg-gradient-to-r from-purple-600 to-purple-800 bg-clip-text text-transparent hover:from-purple-700 hover:to-purple-900 transition-all duration-200 hover:-translate-y-[0.5px]"
            >
                allmygigs
            </Link>.
        </div>
    </footer>
);

export default ProfileFooter;