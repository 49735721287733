import { clsx, type ClassValue } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

// Formats a user-friendly URL input into a proper URL format
export const formatURL = (input: string) => {
  // Remove leading/trailing whitespace
  let url = input.trim();

  // Return empty string if input is empty
  if (!url) return '';

  // Check if the URL already has a protocol
  const hasProtocol = /^[a-zA-Z]+:\/\//.test(url);

  // Add 'https://' if no protocol is present
  if (!hasProtocol) {
    // Remove any leading '//' or 'www.'
    url = url.replace(/^(?:\/\/|www\.)/, '');
    url = `https://${url}`;
  }

  try {
    // Validate the URL format
    new URL(url);
    return url;
  } catch (error) {
    // Return original input if URL is invalid
    return input;
  }
};

const getSocialPrefix = (platform: string) => {
  switch (platform) {
    case 'Instagram': return "www.instagram.com/"
    case 'Twitter': return "www.x.com/"
    case 'YouTube': return "www.youtube.com/@"
    case 'TikTok': return "www.tiktok.com/@"
    case 'Facebook': return "www.facebook.com/"
    case 'LinkedIn': return "www.linkedin.com/in/"
  }
  return ""
}

// Formats a user-friendly URL input into a proper URL format
export const formatSocialURL = (input: string, platform: string) => {
  // Remove leading/trailing whitespace
  let platformUsername = input.trim();
  const platformPrefix = getSocialPrefix(platform)
  // Return empty string if input is empty
  if (!platformUsername) return '';

  // Check if the URL already has a protocol
  const hasProtocol = /^[a-zA-Z]+:\/\//.test(platformUsername);

  // Add 'https://' if no protocol is present
  if (!hasProtocol) {
    // Remove any leading '//' or 'www.'
    platformUsername = platformUsername.replace(/^(?:\/\/|www\.)/, '');
    platformUsername = `https://${platformPrefix}${platformUsername}`;
  }

  try {
    // Validate the URL format
    new URL(platformUsername);
    return platformUsername;
  } catch (error) {
    // Return original input if URL is invalid
    return input;
  }
};

// Username validation rules
export const validateUsername = (username: string) => {
  if (username.length < 3) return "Username must be at least 3 characters";
  if (username.length > 30) return "Username must be less than 30 characters";
  if (!/^[a-zA-Z0-9_-]+$/.test(username)) return "Username can only contain letters, numbers, underscores, and hyphens";
  return null;
};

// Debounce function
export const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const formatDate = (dateStr: string) => {
  const date = new Date(dateStr);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Helper to check if dates are the same day
  const isSameDay = (d1: Date, d2: Date) => {
    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate();
  };

  // Check if it's within the next 6 days
  const diffTime = date.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // If it's today
  if (isSameDay(date, today)) {
    return "Today";
  }

  // If it's tomorrow
  if (isSameDay(date, tomorrow)) {
    return "Tomorrow";
  }

  // If it's within next 6 days
  if (diffDays > 0 && diffDays < 7) {
    return date.toLocaleDateString(undefined, { weekday: 'long' });
  }

  // If it's this year
  if (date.getFullYear() === today.getFullYear()) {
    return date.toLocaleDateString(undefined, {
      month: 'long',
      day: 'numeric'
    });
  }

  // For past dates or dates in future years
  return date.toLocaleDateString(undefined, {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
};

// Will return something like 3:45PM.
export const formatTime = (timeStr: string) => {
  const date = new Date(`1970-01-01T${timeStr}`);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? 'PM' : 'AM';
  const displayHours = hours % 12 || 12; // Convert 0 to 12 for 12 AM

  // If minutes are 0, only show the hour and period
  if (minutes === 0) {
    return `${displayHours} ${period}`;
  }

  return `${displayHours}:${minutes.toString().padStart(2, '0')} ${period}`;
};
