import { Calendar, MapPin, Link as LinkIcon, Plus, ExternalLink, Twitter, Instagram, Youtube, Facebook, Linkedin, Globe, PenLine, Check, Copy } from 'lucide-react';
import { CreateNewEvent } from '@/types/Strings';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { EventResponse } from '@/types/api';
import { useAuth } from '@/components/AuthProvider';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { ProfileResponse } from '~/functions/api/get-profile/[[username]]';
import UserNotFound from './UserNotFound';
import LoadingPage from './LoadingPage';
import ProfileFooter from '@/components/ProfileFooter';
import { formatDate, formatTime } from '@/lib/utils';

interface Event {
  event_id: number;
  title: string;
  venue: string;
  event_date: string;
  start_time: string;
  event_url: string | null;
  description: string | null;
  image_url: string | null;
  created_at: string;
  updated_at: string;
}

interface SocialLink {
  platform: string;
  url: string;
}

interface ProfileData {
  username: string;
  display_name: string | null;
  bio: string | null;
  avatar_url: string | null;
  social_links: SocialLink[];
}

const PLATFORM_ICONS = {
  'Twitter': Twitter,
  'Instagram': Instagram,
  'YouTube': Youtube,
  'Facebook': Facebook,
  'LinkedIn': Linkedin,
  'Website': Globe,
  'TikTok': Globe, // Using Globe as placeholder for TikTok
};

export const UserPage = () => {
  const { user } = useAuth();
  const loggedInUsername = user?.username;
  const { username } = useParams();
  const [events, setEvents] = useState<Event[]>([]);
  const [profile, setProfile] = useState<ProfileData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userFound, setUserFound] = useState(true);
  const [copied, setCopied] = useState(false);


  const isOwnProfile = loggedInUsername === username;

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
  };

  useEffect(() => {
    const fetchProfileAndEvents = async () => {
      try {
        // Fetch profile data
        const profileResponse = await fetch(`/api/get-profile/${username}`);
        if (!profileResponse.ok) {
          if (profileResponse.status == 404) {
            setUserFound(false)
            return
          }
          throw new Error('Failed to fetch profile');
        }
        const profileData = await profileResponse.json() as ProfileResponse;
        if (!profileData) {
          throw new Error('Failed to fetch profile');
        }
        setUserFound(true)
        setProfile(profileData);

        // Fetch events
        const eventsResponse = await fetch(`/api/get-events/${username}`);
        const eventsData: EventResponse = await eventsResponse.json();

        if (!eventsResponse.ok) {
          throw new Error(eventsData.error || 'Failed to fetch events');
        }

        setEvents(eventsData.events);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchProfileAndEvents();
  }, [username]);

  if (!userFound) {
    return <UserNotFound username={username || ''} />
  }

  if (loading) {
    return (
      <LoadingPage />
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col">
        <main className="flex-1 bg-gray-50 pt-12 flex items-center justify-center">
          <div className="text-red-600">{error}</div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <div className="max-w-3xl mx-auto px-4 py-12">
        {/* Profile Header */}
        <div className="flex flex-col items-center mb-8">
          <Avatar className="w-24 h-24 mb-4">
            <AvatarImage src={profile?.avatar_url || ''} />
            <AvatarFallback className="bg-purple-100 text-purple-600 text-xl">
              {username?.[0]?.toUpperCase()}
            </AvatarFallback>
          </Avatar>
          {/* Show display name if provided, else username */}
          <div className="flex items-center gap-3 mb-2">
            {profile?.display_name && <h1 className="text-2xl font-bold text-gray-900">{profile?.display_name}</h1>
              ||
              <h1 className="text-2xl font-bold text-gray-900">@{username}</h1>}
            <button
              onClick={handleCopyUrl}
              className="inline-flex items-center justify-center p-2 rounded-full bg-white shadow-sm hover:shadow-md transition-all hover:scale-110"
              title="Copy profile URL"
            >
              {copied ? (
                <Check className="w-4 h-4 text-green-600" />
              ) : (
                <Copy className="w-4 h-4 text-gray-600" />
              )}
            </button>
          </div>

          {profile?.bio && (
            <p className="text-gray-600 text-center mb-4 max-w-md">{profile?.bio}</p>
          )}

          {/* Social Links */}
          {profile?.social_links && profile.social_links.length > 0 && (
            <div className="flex gap-4 mb-6">
              {profile.social_links.map((link, index) => {
                const IconComponent = PLATFORM_ICONS[link.platform as keyof typeof PLATFORM_ICONS] || Globe;
                return (
                  <a
                    key={index}
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="p-2 rounded-full bg-white shadow-sm hover:shadow-md transition-all hover:scale-110"
                  >
                    <IconComponent className="w-5 h-5 text-gray-600" />
                  </a>
                );
              })}
            </div>
          )}

          {isOwnProfile && (
            <div className="flex gap-4 mt-4">
              <Link
                to="/create-event"
                className="inline-flex items-center px-4 py-2 bg-purple-600 hover:bg-purple-700 text-white rounded-full text-sm font-medium transition-colors"
              >
                <Plus className="h-4 w-4 mr-2" />
                {CreateNewEvent}
              </Link>
              <Link
                to="/edit-user"
                className="inline-flex items-center px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded-full text-sm font-medium transition-colors"
              >
                <PenLine className="h-4 w-4 mr-2" />
                Edit Profile
              </Link>
            </div>
          )}
        </div>

        {/* Events List */}
        <div className="space-y-4">
          {events.length === 0 ? (
            <div className="text-center text-gray-600 py-8">
              No events coming up...
            </div>
          ) : (
            events.map((event) => (
              <a
                key={event.event_id}
                href={event.event_url || '#'}
                target={event.event_url ? "_blank" : undefined}
                rel={event.event_url ? "noopener noreferrer" : undefined}
                className="block w-full"
              >
                <div className="bg-white hover:scale-105 transform transition-all duration-200 rounded-xl shadow-sm hover:shadow-md p-6 border border-gray-100">
                  <div className="flex justify-between items-start">
                    <div className="flex-1">
                      <h2 className="text-lg font-semibold text-gray-900 mb-2">
                        {event.title}
                      </h2>

                      <div className="space-y-2">
                        <div className="flex items-center text-gray-600 text-sm">
                          <Calendar className="h-4 w-4 mr-2 flex-shrink-0" />
                          <span>{formatDate(event.event_date)} at {formatTime(event.start_time)}</span>
                        </div>

                        <div className="flex items-center text-gray-600 text-sm">
                          <MapPin className="h-4 w-4 mr-2 flex-shrink-0" />
                          <span>{event.venue}</span>
                        </div>

                        {event.description && (
                          <p className="text-gray-600 text-sm line-clamp-2 mt-2">
                            {event.description}
                          </p>
                        )}
                      </div>
                    </div>

                    {event.event_url && (
                      <ExternalLink className="h-5 w-5 text-gray-400 flex-shrink-0 ml-4" />
                    )}
                  </div>
                </div>
              </a>
            ))
          )}
        </div>

        <ProfileFooter />
      </div>
    </div>
  );
};
