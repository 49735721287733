import { Calendar, Clock, Link as LinkIcon, MapPin, Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { EventCreationResponse } from '@/types/api';
import { useAuth } from '@/components/AuthProvider';
import { CreateNewEvent } from '@/types/Strings';

const EventCreationPage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);

    if (!user || !user.username) {
        navigate('/')
    }

    const [formData, setFormData] = useState({
        title: '',
        venue: '',
        eventDate: '',
        startTime: '',
        eventUrl: '',
        description: '',
        userId: '',
    });

    // Get today's date in YYYY-MM-DD format for min date
    const today = new Date().toISOString().split('T')[0];

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError(null);

        try {
            const formDataToSend = new FormData();
            Object.entries(formData).forEach(([key, value]) => {
                formDataToSend.append(key, value);
            });
            if (user) {
                formDataToSend.set('userId', String(user.id));
            }
            const response = await fetch('/api/create-event', {
                method: 'POST',
                body: formDataToSend,
            });

            if (!response.ok) {
                const data: EventCreationResponse = await response.json();
                throw new Error(data.message || 'Failed to create event');
            }

            setSuccess(true);
            setTimeout(() => {
                navigate(`/${user?.username}`);
            }, 2000);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex flex-col items-center p-4">
            <div className="w-full max-w-xl py-8">
                <Card className="border-0 shadow-lg">
                    <CardHeader className="text-center">
                        <CardTitle className="text-2xl font-semibold">{CreateNewEvent}</CardTitle>
                        <CardDescription className="text-gray-500">
                            Share your next performance with your followers
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            {/* Title */}
                            <div className="space-y-2">
                                <Input
                                    value={formData.title}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        title: e.target.value
                                    }))}
                                    required
                                    placeholder="Event Title *"
                                    className="h-12 text-lg px-4 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                />
                            </div>

                            {/* Venue */}
                            <div className="relative">
                                <MapPin className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                                <Input
                                    value={formData.venue}
                                    onChange={(e) => setFormData(prev => ({
                                        ...prev,
                                        venue: e.target.value
                                    }))}
                                    required
                                    placeholder="Venue *"
                                    className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                />
                            </div>

                            {/* Date and Time */}
                            <div className="grid grid-cols-2 gap-4">
                                <div className="relative">
                                    <Calendar className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                                    <Input
                                        type="date"
                                        value={formData.eventDate}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            eventDate: e.target.value
                                        }))}
                                        min={today}
                                        required
                                        className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                    />
                                </div>
                                <div className="relative">
                                    <Clock className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                                    <Input
                                        type="time"
                                        value={formData.startTime}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            startTime: e.target.value
                                        }))}
                                        required
                                        className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                    />
                                </div>
                            </div>

                            {/* URLs */}
                            <div className="space-y-4">
                                <div className="relative">
                                    <LinkIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
                                    <Input
                                        type="url"
                                        value={formData.eventUrl}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            eventUrl: e.target.value
                                        }))}
                                        placeholder="Event URL (optional)"
                                        className="h-12 pl-12 border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                                    />
                                </div>
                            </div>

                            {/* Description */}
                            <Textarea
                                value={formData.description}
                                onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    description: e.target.value
                                }))}
                                placeholder="Event Description (optional)"
                                className="h-32 resize-none border-gray-200 focus:border-purple-500 focus:ring-purple-500"
                            />

                            {error && (
                                <Alert variant="destructive" className="mt-4">
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}

                            {success && (
                                <Alert className="mt-4 bg-green-50 text-green-700 border-green-200">
                                    <AlertDescription>
                                        Event created successfully! Redirecting to your profile... ✨
                                    </AlertDescription>
                                </Alert>
                            )}

                            <Button
                                type="submit"
                                className="w-full h-12 bg-purple-600 hover:bg-purple-700 transition-colors"
                                disabled={isLoading || !formData.title || !formData.venue || !formData.eventDate || !formData.startTime}
                            >
                                {isLoading ? (
                                    <Loader2 className="w-4 h-4 animate-spin" />
                                ) : (
                                    'Create Event'
                                )}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default EventCreationPage;