import { Loader2, Upload, User, AtSign, FileText, Link, Plus, Trash2, Calendar, Share } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@/components/AuthProvider';
import { Alert, AlertDescription } from '@/components/ui/alert';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { UsernameCheckResponse } from '@/types/api';
import { debounce, formatSocialURL, formatURL, validateUsername } from '@/lib/utils';
import { RegistrationResponse } from '~/functions/api/register-user';
import { PLATFORMS } from '@/types/constants';

interface SocialLink {
    platform: string;
    url: string;
}


const RegistrationPage = () => {
    const { user, setCurrentUser } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [isCheckingUsername, setIsCheckingUsername] = useState(false);
    const [usernameError, setUsernameError] = useState<string | null>(null);
    const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);

    if (user?.username) {
        navigate(`/edit-user`)
    }
    if (!user) {
        navigate('/')
    }

    const [formData, setFormData] = useState({
        username: '',
        displayName: '',
        bio: '',
    });

    const [socialLinks, setSocialLinks] = useState<SocialLink[]>([]);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const checkUsernameAvailability = async (username: string) => {
        if (!username) {
            setIsUsernameAvailable(false);
            return;
        }

        const validationError = validateUsername(username);
        if (validationError) {
            setUsernameError(validationError);
            setIsUsernameAvailable(false);
            return;
        }

        try {
            setIsCheckingUsername(true);
            const response = await fetch(`/api/validate-username/${username}`);
            const data = await response.json() as UsernameCheckResponse;

            setIsUsernameAvailable(data.available);
            if (!data.available) {
                setUsernameError("This username is already taken");
            } else {
                setUsernameError(null);
            }
        } catch (error) {
            setUsernameError("Error checking username availability");
            setIsUsernameAvailable(false);
        } finally {
            setIsCheckingUsername(false);
        }
    };

    const debouncedUsernameCheck = debounce(checkUsernameAvailability, 500);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase().trim();
        setFormData(prev => ({
            ...prev,
            username: value
        }));

        // First check basic validation
        const validationError = validateUsername(value);
        setUsernameError(validationError);
        setIsUsernameAvailable(false);

        // If basic validation passes, check availability
        if (!validationError) {
            debouncedUsernameCheck(value);
        }
    };

    // Username status message component
    const getUsernameStatus = () => {
        if (isCheckingUsername) {
            return (
                <p className="text-sm text-gray-500 flex items-center gap-2">
                    <Loader2 className="w-3 h-3 animate-spin" />
                    Checking availability...
                </p>
            );
        }
        if (usernameError) {
            return <p className="text-sm text-red-500">{usernameError}</p>;
        }
        if (formData.username && isUsernameAvailable) {
            return <p className="text-sm text-green-500">Username is available! ✨</p>;
        }
        return null;
    };

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAddSocialLink = () => {
        setSocialLinks(prev => [...prev, { platform: PLATFORMS[0], url: '' }]);
    };

    const handleRemoveSocialLink = (index: number) => {
        setSocialLinks(prev => prev.filter((_, i) => i !== index));
    };

    const handleSocialLinkChange = (index: number, field: keyof SocialLink, value: string) => {
        setSocialLinks(prev =>
            prev.map((link, i) => {
                if (i === index) {
                    const newValue =
                        field === 'url'
                            ? formatSocialURL(value, link.platform) // Pass both URL and platform here
                            : value;
                    return { ...link, [field]: newValue };
                }
                return link;
            })
        );
    };


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!user?.email || usernameError || isCheckingUsername || !formData.username) {
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('email', user.email);
            formDataToSend.append('username', formData.username);
            formDataToSend.append('displayName', formData.displayName || '');
            formDataToSend.append('bio', formData.bio || '');
            if (imageFile) {
                formDataToSend.append('imageFile', imageFile);
            }
            formDataToSend.append('googleImageUrl', user.imageUrl || '');

            // Add social links
            if (socialLinks.length > 0) {
                formDataToSend.append('socialLinks', JSON.stringify(socialLinks));
            }

            const response = await fetch('/api/register-user', {
                method: 'POST',
                body: formDataToSend,
            });

            const data = await response.json() as RegistrationResponse;

            if (!response.ok) {
                throw new Error(data.error || 'Registration failed');
            }
            const updatedUser = data.user;
            setCurrentUser(updatedUser || {
                id: user.id,
                email: user.email,
                username: formData.username,
            })

            setSuccess(true);
            setTimeout(() => {
                navigate(`/${formData.username}`);
            }, 2000);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Something went wrong');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white flex items-center justify-center p-4">
            <div className="w-full max-w-4xl grid md:grid-cols-2 gap-8 items-center">
                {/* Left side - Welcome message */}
                <div className="hidden md:block space-y-6 p-8">
                    <h1 className="text-4xl font-bold text-purple-900">Welcome to allmygigs</h1>
                    <p className="text-lg text-purple-700"></p>
                    <div className="space-y-4 text-purple-600">
                        <div className="flex items-center gap-3">
                            <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center">
                                <AtSign className="w-4 h-4" />
                            </div>
                            <p>Choose your unique username</p>
                        </div>
                        <div className="flex items-center gap-3">
                            <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center">
                                <Calendar className="w-4 h-4" />
                            </div>
                            <p>List your upcoming gigs</p>
                        </div>

                        <div className="flex items-center gap-3">
                            <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center">
                                <Share className="w-4 h-4" />
                            </div>
                            <p>Share with your followers</p>
                        </div>
                    </div>
                </div>

                {/* Right side - Registration form */}
                <Card className="w-full shadow-lg border-0">
                    <CardHeader className="space-y-1">
                        <CardTitle className="text-2xl">Complete Your Profile</CardTitle>
                        <CardDescription className="text-base">
                            Add some details to personalize your account
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="flex flex-col items-center space-y-4">
                                <div className="relative group">
                                    <Avatar className="w-32 h-32 border-4 border-purple-100 group-hover:border-purple-200 transition-all">
                                        <AvatarImage src={imagePreview || user?.imageUrl} />
                                        <AvatarFallback className="text-2xl bg-purple-100">
                                            {formData.displayName?.charAt(0) || formData.username?.charAt(0) || user?.displayName?.charAt(0) || '?'}
                                        </AvatarFallback>
                                    </Avatar>
                                    <Input
                                        id="picture"
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        onChange={handleImageChange}
                                    />
                                    <Button
                                        type="button"
                                        variant="outline"
                                        onClick={() => document.getElementById('picture')?.click()}
                                        className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 bg-white hover:bg-purple-50"
                                    >
                                        <Upload className="w-4 h-4 mr-2" />
                                        Upload
                                    </Button>
                                </div>
                            </div>

                            <div className="space-y-4">
                                <div className="space-y-2">
                                    <Label htmlFor="username" className="text-sm font-medium">Username</Label>
                                    <div className="relative">
                                        <Input
                                            id="username"
                                            value={formData.username}
                                            onChange={handleUsernameChange}
                                            required
                                            placeholder="Choose your username"
                                            className={`pl-8 ${usernameError ? "border-red-500 focus:ring-red-500" : "focus:ring-purple-500"}`}
                                        />
                                        <AtSign className="w-4 h-4 absolute left-2.5 top-1/2 -translate-y-1/2 text-gray-400" />
                                    </div>
                                    {getUsernameStatus()}
                                    <p className="text-sm text-gray-500">
                                        Your profile: allmygigs.com/{formData.username || 'username'}
                                    </p>
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="displayName" className="text-sm font-medium">Display Name (Optional)</Label>
                                    <Input
                                        id="displayName"
                                        value={formData.displayName}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            displayName: e.target.value
                                        }))}
                                        placeholder="Will override username on the events page"
                                        className="focus:ring-purple-500"
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="bio" className="text-sm font-medium">Bio (Optional)</Label>
                                    <textarea
                                        id="bio"
                                        value={formData.bio}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            bio: e.target.value
                                        }))}
                                        placeholder="Tell us about yourself..."
                                        className="w-full h-24 px-3 py-2 border rounded-md focus:ring-purple-500 focus:border-purple-500"
                                    />
                                </div>

                                <div className="space-y-4">
                                    <div className="flex items-center justify-between">
                                        <Label className="text-sm font-medium">Social Links</Label>
                                        <Button
                                            type="button"
                                            variant="outline"
                                            size="sm"
                                            onClick={handleAddSocialLink}
                                            className="text-purple-600 hover:text-purple-700"
                                            disabled={socialLinks.length >= PLATFORMS.length}
                                        >
                                            <Plus className="w-4 h-4 mr-2" />
                                            Add Link
                                        </Button>
                                    </div>

                                    {socialLinks.map((link, index) => (
                                        <div key={index} className="flex gap-2 items-start">
                                            <select
                                                value={link.platform}
                                                onChange={(e) => handleSocialLinkChange(index, 'platform', e.target.value)}
                                                className="w-1/3 px-3 py-2 border rounded-md focus:ring-purple-500 focus:border-purple-500"
                                            >
                                                {PLATFORMS.map(platform => (
                                                    <option key={platform} value={platform}>
                                                        {platform}
                                                    </option>
                                                ))}
                                            </select>
                                            <Input
                                                value={link.url}
                                                onChange={(e) => handleSocialLinkChange(index, 'url', e.target.value)}
                                                placeholder={`Your ${link.platform} username`}
                                                className="flex-1"
                                                type="url"
                                            />
                                            <Button
                                                type="button"
                                                variant="ghost"
                                                size="icon"
                                                onClick={() => handleRemoveSocialLink(index)}
                                                className="text-gray-400 hover:text-red-500"
                                            >
                                                <Trash2 className="w-4 h-4" />
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            {error && (
                                <Alert variant="destructive" className="mt-4">
                                    <AlertDescription>{error}</AlertDescription>
                                </Alert>
                            )}

                            {success && (
                                <Alert className="mt-4 bg-green-50 text-green-700 border-green-200">
                                    <AlertDescription>
                                        Profile updated successfully! Redirecting... ✨
                                    </AlertDescription>
                                </Alert>
                            )}

                            <Button
                                type="submit"
                                className="w-full bg-purple-600 hover:bg-purple-700"
                                disabled={isLoading || !formData.username || !!usernameError || isCheckingUsername || !isUsernameAvailable}
                            >
                                {isLoading && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
                                Complete Registration
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );;
};

export default RegistrationPage;